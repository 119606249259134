import { globalTheme } from "srs.sharedcomponents/lib/esm/styles/config";

export const customTheme = {
  colors: {
    ...globalTheme.colors,

    warningLight: "#edeff4",

    warningLightTextColor: "#002469",
  },
};
