import React from "react";
import ReactDOM from "react-dom";
import AppWrapper from "./AppWrapper";
import { store } from "srs.sharedcomponents/lib/esm/redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { injectStore } from "srs.sharedcomponents/lib/esm/api/agent";
import Spinner from "srs.sharedcomponents/lib/esm/components/Spinner";
import { BrowserRouter as Router } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { env } from "srs.sharedcomponents/lib/esm/api/env";
import { setSchema } from "srs.sharedcomponents/lib/esm/schemas";
import RegistrationSchema from "./schemas/registration";
import { ConfigProvider } from "srs.sharedcomponents/lib/esm/components/context/appConfig";
import { appConfig } from "./config/app.config";
import "./css/main.css";
import { ComponentsProvider } from "srs.sharedcomponents/lib/esm/components/context/componentsContext";
import { componentsConfig as defaultConfig } from "srs.sharedcomponents/lib/esm/components/config";
import { ThemeProvider } from "srs.sharedcomponents/lib/esm/components/context/themeContext";
import ThemeWrapper from "srs.sharedcomponents/lib/esm/components/molecules/ThemeWrapper/ThemeWrapper";
import { customTheme } from "./styles/theme";
injectStore(store);
const persistor = persistStore(store);

const initApplicationInsights = () => {
  return new ApplicationInsights({
    config: {
      connectionString: env.APPLICATIONINSIGHTS_CONNECTION_STRING,
      /* ...Other Configuration Options... */
    },
  });
};

const appInsights = initApplicationInsights();

if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}
setSchema("registration", RegistrationSchema);
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider appConfig={appConfig}>
      <ComponentsProvider componentsConfig={defaultConfig}>
        <Provider store={store}>
          <ThemeProvider>
            <ThemeWrapper theme={customTheme}>
              <PersistGate loading={<Spinner />} persistor={persistor}>
                <Router>
                  <AppWrapper appInsights={appInsights} />
                </Router>
              </PersistGate>
            </ThemeWrapper>
          </ThemeProvider>
        </Provider>
      </ComponentsProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
